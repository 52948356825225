




























































































































































































































































import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';
import {
  // getMenuTree,
  getMenuTreeApi,
  getpostQuestionListApi,
  getDisease,
  delDiseaseApi,
  getDetailDiseaseApi,
  getpostQuestionListUpdate,
  getItemPageList,
  getItemById,
  getDeleteItem,
  getAddItem,
  getUpdateItem,
  getAddAnswer
} from '@/serve/evaluatingBefore/evaluatingQuestion';

import {
  getItemData
} from '@/serve/medicalAppointment/itemsManage';
import { getTenantData } from '@/serve/medicalAppointment/institutionManage';
// import { enums } from '@/utils/commit'
interface DynamicTags {
  quesOptionContent: string;
  sort: string | number;
  quesOptionRelaVOList: QuesOptionRelaVOList[];
  id?: number | string;
}
interface QuesOptionRelaVOList {
  diseaseScore: number | string; //测评指数
  diseaseId: string | number; //疾病系统id
  id?: number;
}
interface AddData<T, U> {
  quesDesc: T;
  quesKey: T;
  quesName: T;
  quesType: T;
  id?: U;
  quesSubjectList: {
    quesSubjectContent: T;
    quesSubjectIsRequired: T;
    quesSubjectType: T;
    sort: T | U;
    id?: U;
    quesId?: U;
    quesOptionList: {
      quesOptionContent: T; //问卷选项内容
      sort: T | U; //排序
      quesOptionRelaVOList: {
        diseaseScore: T; //测评指数
        diseaseId: T | U; //疾病系统id
      }[];
    }[];
  }[];
}
// interface QuestionData<T, U> {
//   quesDesc: T; //问卷描述
//   quesKey: T; // 问卷关键字
//   quesName: T; // 问卷名称
//   quesType: T; //问卷类型
//   id?: T | U; //问卷id
// }
interface TopicData<T, U> {
  check?: T | boolean;
  quesSubjectContent: T;
  quesSubjectIsRequired: T;
  quesSubjectType: T;
  sort: T | U;
  id?: U;
  quesId?: U;
  arr?: (T | U)[];
  item?: T | undefined;
  itemSort?: T | U | undefined;
  diseaseType?: T | undefined;
  scoreModel?: T | U | undefined;
  projectList?: QuesOptionRelaVOList[];
  clearList?: DynamicTags[];
  quesOptionList: DynamicTags[];
  [propName: string]: any;
}
@Component({
  name: 'evaluatingQuestionCreate',
})
export default class EvaluatingQuestionCreate extends Vue {
  public currentPage4 = 1;
  public formLabelWidth = '120px';
  public tableData = [];
  public diseaseType = '';
  public checkQuestion = true;
  public scoreModel = '';
  public riskModel = '';
  public resData: TopicData<string, number>[] = [];
  public enums: any = {
  // 类别
  category: {
    '0': '基础',
    '1': '一般',
    '2': '深度'
  },
  type: {
    '0': '男',
    '1': '女'
  },
  scene: {
    '0': '未婚',
    '1': '已婚'
  },
  //0单选 1多选
  types:{
    '0': '单选',
    '1': '多选'
  }
}
  // public quesOptionRelaVOList: QuesOptionRelaVOList[] = [];
  public rules = {
    name: [{ required: true, message: '请填写问卷名称', trigger: 'blur' }],
    description: [{ required: true, message: '请填写描述信息', trigger: 'blur' }],

  };
  public rulesAdd = {
    types: [{ required: true, message: '请选择问题类型', trigger: 'blur' }],
    title: [{ required: true, message: '请填写问题名称', trigger: 'blur' }],
    sort: [{ required: true, message: '请填写问题序号', trigger: 'blur' }],
  }
  // 添加确定按钮
  public async submitForm() {
    (this.$refs['formAdd'] as ElForm).validate(async (valid) => {
      if (valid) {

        console.log("000", this.formAdd);
        this.formAdd.questionnaireId = this.qid
        console.log("11", this.formAdd);
        const res = await getAddItem(this.formAdd);
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '添加成功!',
          });
          this.isShowTopicAdd = false
          for (const key in this.formAdd) {

            this.formAdd[key] = ''

          }
          this.editRow(this.qid)
        }




      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }

  //题目编辑按钮 
  public async editTopicUpdate(questionItem: TopicData<string, number>, index: number) {
    console.log("----", this.formData.quesSubjectList[index].isShowEditUpdate);
    this.formData.quesSubjectList[index].isShowEditUpdate = false
  }

  // 题目提交按钮
  public async postQuestionUpdate(questionItem: TopicData<string, number>, index: number) {


    console.log("mm", questionItem);
    // getUpdateItem

    const params = {
      id: questionItem.id,
      questionnaireId: this.qid,
      title: questionItem.title,
      type: questionItem.type,
      sort: questionItem.sort
    }
    const res = await getUpdateItem(params);
    if (res.code === 200) {
      this.$message({
        type: 'success',
        message: '更新成功!',
      });
      // this.isShowTopicAdd = false
      this.formData.quesSubjectList[index].isShowEditUpdate = true

      this.editRow(this.qid)
    }
  }

  // 添加取消按钮
  public resetFormAdd() {
    this.isShowTopicAdd = false
  }

  public formData = {
    name: '',
    description: '',
    category: '',
    type: '',
    scene: '',
    quesSubjectList: this.resData,
    // quesName: '',
    // quesDesc: '',
    // quesType: '检前评估',
    // quesKey: '',

  };
  public tenantName = '';
  public isAdmin = sessionStorage.getItem('isAdmin');
  public restaurants = [];
  public options = [
    {
      value: '0',
      label: '单选',
    },
    {
      value: '1',
      label: '多选',
    },
  ];
  // public typeOptions = [
  //   {
  //     value: '检前评估',
  //     label: '检前评估',
  //   },
  //   {
  //     value: '健康测评',
  //     label: '健康测评',
  //   },
  // ];
  public form = {
    size: 10,
    current: 1,
    keyword: '',
    // tenantId: '',
  };
  // TODO:风险评估
  public addNum = 1;
  public category = [{
    value: '0',
    label: '基础'
  }, {
    value: '1',
    label: '一般'
  }, {
    value: '2',
    label: '深度'
  }];
  public sex = [{
    value: '0',
    label: '男'
  }, {
    value: '1',
    label: '女'
  }];
  public status = [{
    value: '0',
    label: '未婚'
  }, {
    value: '1',
    label: '已婚'
  }];
  public answerPageList = []; //答案列表
  public qid = 0; //问卷id

  public tabNewData: any = []; //tab选项

  public isShowTopic = true;//题目编辑按钮

  public isShowTopicAdd = false; //添加题目弹窗

  // public isShowEditUpdate = true; // 题目的修改
  public tabList = {
    option: "",//选项
    serial: "",//序号
    project: []//项目
  }

  public formAdd: any = { //添加题目弹窗参数
    questionnaireId: 0,
    types: "",
    title: "",
    sort: 0
  }

  public formLabelWidths = '120px'

  // TODO:风险评估 end
  // public enums = enums;
  public editNum = 1;
  public dynamicTags: DynamicTags[] = [];
  public inputVisible = false;
  public inputValue = '';
  public value = '';
  public liIsShow = false;
  public dialogFormVisible = false;
  public disease = [];
  public topicSort = '';
  public itemSort = '';
  public typeInter = '';
  public async created(): Promise<void> {
    this.getQuestionList();
    this.getDiseaseList();
  }
  public mounted() {
    this.getTenantData();
  }
  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
  // public handleSelect(e: any) {
  //   if (Object.keys(e).length > 0) this.form.tenantId = e.tId;
  //   else this.form.tenantId = '';
  // }
  // 问题编辑
  public editTopic() {

    this.isShowTopic = false
  }
  // 问题返回
  public backtrack() {
    this.checkQuestion = !this.checkQuestion
    this.isShowTopic = true
  }
  // 筛选按钮
  public onSubmit() {
    this.getQuestionList();
  }
  // 重置功能
  public resetForm(formName: string) {
    (this.$refs[formName] as ElForm).resetFields();
    this.tenantName = '';
    // this.form.tenantId = '';
    this.currentPage4 = 1;
    this.getQuestionList();
    this.getDiseaseList();
  }
  // 获取项目数据
  public async getDiseaseList() {
    const data = {
      current: 1,
      size: 999,
      checkItemName: ''
    };
    // const res = await getDisease();
    // this.disease = res.data.records;
    const res = await getItemData(data);
    console.log("项目列表", res.data);
    this.disease = res.data.records;
  }
  // 获取问卷题目列表
  public async getQuestionList() {
    // this.$changNull(this.form);
    console.log("this.form", this.form);
    const res = await getMenuTreeApi(this.form);

    this.tableData = res.data;
  }
  // 编辑按钮
  public async editRow(id: number): Promise<void> {
    this.typeInter = '编辑';
    this.isShowTopic = true
    this.qid = id

    // console.log("ress",res.data);
    const res = await getDetailDiseaseApi(id);


    this.checkQuestion = !this.checkQuestion;


    const params = {
      size: 999,//没有分页，固定死
      current: 1,
      keyword: '',
      questionnaireId: id
    }
    const ress = await getItemPageList(params)
    console.log("问题", ress.data);

    const newData = res.data;

    newData.quesSubjectList = ress.data.records
    for (let n = 0; n < newData.quesSubjectList.length; n++) {
      newData.quesSubjectList[n].isShowEditUpdate = true

    }
    this.formData = newData;
    this.addNum = this.formData.quesSubjectList.length + 1;

    // 查询答案列表，遍历出答案 getItemById
    const paramsTD = {
      size: 999,//没有分页，固定死
      current: 1,
      keyword: '',

    }
    const resTD = await getItemById(paramsTD)
    console.log("答案列表", newData.quesSubjectList);

    this.answerPageList = resTD.data



    let resData;

    for (const key in this.formData.quesSubjectList) {

      resData = this.formData.quesSubjectList[key];
      resData.projectList = []
      resData.arr = []
      resData.clearList = []
      // resData.isShowEditUpdate=true


      const arrNew = []

      const answer: any = JSON.parse(resData.answers)

      // 筛选出题目对应的答案列表
      for (let index = 0; index < resTD.data.records.length; index++) {
        for (let i = 0; i < answer.length; i++) {
          if (resTD.data.records[index].id === answer[i]) {
            const newDerivedContent = JSON.parse(resTD.data.records[index].derivedContent)
            resTD.data.records[index].derivedContent = newDerivedContent
            // console.log("-=-",resTD.data.records[index]);
            // this.tabNewData.push(resTD.data.records[index])
            resData.clearList.push(resTD.data.records[index])

            arrNew.push(resTD.data.records[index])
          }

        }

      }
      this.tabNewData = resData.clearList




      this.$set(resData as any, 'projectList', []);
      this.$set(resData as any, 'arr', []);
      this.$set(resData as any, 'clearList', arrNew);


    }

    console.log("formDatahhh", this.formData);

  }
  // 删除按钮
  public async deleteRow(id: number, row: any): Promise<void> {
    (this as any).$messageBox
      .confirm(`您确定是否删除问卷名称为${row.name}的这一条数据`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
      .then(async () => {
        const res = await delDiseaseApi(id);
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          this.getQuestionList();
          this.getDiseaseList();
        }
      });
  }
  /**头部查询搜索机构事件 */
  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  /** 清空机构名称事件 */
  // public clearName() {
  //   this.form.tenantId = '';
  // }
  public handleSizeChange(val: any): void {
    this.form.size = val;
    this.getQuestionList();
    this.getDiseaseList();
  }
  public handleCurrentChange(val: any): void {
    this.form.current = val;
    this.getQuestionList();
    this.getDiseaseList();
  }
  // 添加题目
  public addTopic() {
    console.log("123", this.addNum);
    this.formAdd.sort = this.addNum
    this.isShowTopicAdd = true


  }
  // 新增选项方法
  public async addItem(index: number, questionItem: any) {
    const resData = this.formData.quesSubjectList[index];
    console.log("选项", questionItem);
    if (
      this.tabList.option === '' ||
      this.tabList.project.length === 0 ||
      this.tabList.serial === ''
    ) {
      this.$message({
        message: '请先将题目信息填写完整',
        type: 'warning',
      });
    } else {
      // getAddAnswer
      const params = {
        questionnaireItemId: questionItem.id,
        content: this.tabList.option,
        sort: this.tabList.serial,//序号
        derivedContent: {
          checkItems: this.tabList.project,
          checkPackages: []
        }
      }
      const res = await getAddAnswer(params);
      console.log("res", res);
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '新增成功!',
        });
        this.tabList = {
          option: "",//选项
          serial: "",//序号
          project: []//项目
        }
        this.getQuestionList();
        this.getDiseaseList();
      }
      // 序号随着选项保存增加而增加
      resData.itemSort = resData.quesOptionList.length + 1;
    }
  }
  // 选项删除按钮
  public delItem(index: number, indexs: number | string) {
    const resData = this.formData.quesSubjectList[index];
    console.log(resData.quesOptionList);
    resData.quesOptionList.splice(Number(indexs), 1);
    if (resData.quesOptionList.length === 0) {
      resData.addDisabled = false;
    }
    this.refreshData(index);
  }
  // 这是重置数据
  public refreshData(index: number) {
    const resData = this.formData.quesSubjectList[index];

    this.$set(resData, 'item', '');
    this.$set(resData, 'itemSort', '');
    this.$set(resData, 'diseaseType', '');
    this.$set(resData, 'scoreModel', '');
    this.$set(resData, 'arr', []);
    this.$set(resData, 'projectList', []);
  }


  // 当输入框值改变的时候 绑定的值也跟着改变
  public scoreChange(value: number, index: number) {
    const resData = this.formData.quesSubjectList[index];
    let distype = '';
    console.log(resData.diseaseType);
    for (const key in this.disease) {
      if ((this.disease[key] as any).checkItemName === resData.diseaseType) {
        distype = (this.disease[key] as any).id;
      }
    }
    console.log(resData.projectList);
    console.log(distype);

    // this.riskModel值是改变疾病框后存起来的值 如果projectList数组里的id值等于riskModel 那么diseaseScore 就等于传入的value（分数）
    for (const key in resData.projectList as QuesOptionRelaVOList[]) {
      if (
        (resData.projectList as QuesOptionRelaVOList[])[key].diseaseId ===
        distype
      ) {
        (resData.projectList as QuesOptionRelaVOList[])[
          key
        ].diseaseScore = value;
      }
    }
  }

  // 题目类型改变
  public itemChange(value: string, index: number) {
    console.log("value", value);
    this.formData.quesSubjectList[index].type = value
    // const resData = this.formData.quesSubjectList[index];

    // resData.check = value;
    this.refreshData(index);
    // this.$set(resData, 'projectList', []);
  }
  // 题目保存
  public topicSave(index: number) {
    console.log(index);
  }
  // 题目删除
  public topicDel(index: number, questionItem: any) {

    console.log("///", questionItem);
    if (questionItem.createTime) {
      (this as any).$messageBox
        .confirm(`该信息已存入库，您确定要删除问卷名称为${questionItem.title}的这一条数据`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(async () => {
          console.log("11122");
          const paramsDel = {
            id: questionItem.id
          }
          const res = await getDeleteItem(paramsDel);
          console.log(res);
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
            this.formData.quesSubjectList.splice(index, 1);

          }
        });
    } else {
      this.formData.quesSubjectList.splice(index, 1);
    }
  }

  // 点击选项列表
  public tagClick(items: DynamicTags, index: number, indexs: number | string) {
    console.log(items);

    const resData = this.formData.quesSubjectList[index];
    this.$set(resData, 'tagIndex', Number(indexs));
    this.$set(resData, 'uid', (items as any).id ? (items as any).id : null);
    this.$set(resData, 'addDisabled', true);
    const arr = [];
    for (const key in items.quesOptionRelaVOList) {
      arr.push(items.quesOptionRelaVOList[key].diseaseId);
    }
    this.$set(resData, 'arr', arr);
    this.$set(resData, 'item', items.quesOptionContent);
    this.$set(resData, 'itemSort', items.sort);
    // const newArr: any[] = [];
    // resData.quesOptionList.forEach((e) => {
    //   newArr.push(e);
    // });
    this.$set(resData, 'projectList', items.quesOptionRelaVOList);
    if ((resData.projectList as []).length === 0) {
      resData.diseaseType = '';
      resData.scoreModel = '';
    }
    if (items.quesOptionRelaVOList.length > 0) {
      const diseaseId = items.quesOptionRelaVOList[0].diseaseId;
      for (const key in this.disease) {
        if (diseaseId === (this.disease[key] as any).id) {
          this.$set(
            resData,
            'diseaseType',
            (this.disease[key] as any).checkItemName
          );
        }
      }
      this.$set(
        resData,
        'scoreModel',
        items.quesOptionRelaVOList[0].diseaseScore
      );
    }
  }
  // 加号按钮
  public tagAddClick(index: number) {
    this.formData.quesSubjectList[index].addDisabled = false;
    this.refreshData(index);
  }
  // 问卷提交按钮
  public async postQuestion() {
    // formData
    (this.$refs['formData'] as ElForm).validate(async (valid) => {
      if (valid) {
        // getpostQuestionListUpdate
        console.log("000", this.formData);
        if (this.typeInter === "新增") {
          const res = await getpostQuestionListApi(this.formData);
          if (res.code === 200) {
            this.$message({
              message: '提交成功',
              type: 'success',
            });
            this.getQuestionList();
            this.checkQuestion = !this.checkQuestion;

            this.formData = {
              name: '',
              description: '',
              category: '',
              type: '',
              scene: '',
              quesSubjectList: [],

            }
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
            });
          }
        } else {

          const res = await getpostQuestionListUpdate(this.formData);
          if (res.code === 200) {
            this.$message({
              message: '更新成功',
              type: 'success',
            });
            this.getQuestionList();
            this.checkQuestion = !this.checkQuestion;
            this.formData = {
              name: '',
              description: '',
              category: '',
              type: '',
              scene: '',
              quesSubjectList: [],

            }
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
            });
          }
        }


      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }
  // 疾病名字筛选
  public filterName(data: string | number) {
    for (const key in this.disease) {
      if ((this.disease[key] as any).id === data) {
        return (this.disease[key] as any).checkItemName;
      }
    }
  }
  // 添加问卷按钮
  public addClearQuestion() {
    this.checkQuestion = !this.checkQuestion;
    this.formData = {
      name: '',
      description: '',
      category: '',
      type: '',
      scene: '',
      quesSubjectList: [],
    };
    this.typeInter = '新增';
    this.isShowTopic = false
    this.addNum = 1;
  }
  // 删除选项中某项疾病
  public delRisk(index: number, liIndex: number) {
    const resData = this.formData.quesSubjectList[index];
    (resData.projectList as []).splice(liIndex, 1);
    (resData.arr as []).splice(liIndex, 1);
    resData.diseaseType = '';
    resData.scoreModel = '';
    console.log(resData);
  }
}
